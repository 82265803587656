// src/utils/auth.js
import React from 'react';
// import auth0 from "auth0-js"
import { navigate } from "gatsby"
import i18n from "../i18n"; // this is required, even if not used
// import i18next from 'i18next'
// import { contains } from 'ramda';
// import { globalHistory } from '@gatsbyjs/reach-router';

import SmoothScroll from './smoothScroll';


// import convertLinkLocale from './convertLinkLocale';
// import supportedLngs from '../locales/supportedLngs';
import { MEDIUM_BREAKPOINT } from './breakpoints';
// import CookiesPolicy from '../components/CookiesPolicy/CookiesPolicy';

export const isBrowser = typeof window !== "undefined" && window;

const databaseConnection = 'Username-Password-Authentication';

const authConfig = {
  domain: process.env.GATSBY_AUTH0_DOMAIN,
  clientID: process.env.GATSBY_AUTH0_CLIENTID,
  redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
  responseType: "token id_token",
  scope: "openid profile email",
}


function initAuth(){
  return new window.auth0.WebAuth(authConfig)
}

export const auth = {
  blank: () => {},

  getter( name, args ){
    if( typeof window == "undefined" || typeof window.auth0 == "undefined" ) {
      return this.blank;
    }

    if( typeof window.authAgent == "undefined" ) {
      window.authAgent = initAuth();
    }

    if( typeof window.authAgent[name] == "undefined" ) {
      return this.blank;
    }

    return window.authAgent[name].call( window.authAgent, ...args );
  },

  login(){ return this.getter( 'login', arguments ) },
  authorize(){ return this.getter( 'authorize', arguments ) },
  changePassword(){ return this.getter( 'changePassword', arguments ) },
  checkSession(){ return this.getter( 'checkSession', arguments ) },
  parseHash(){ return this.getter( 'parseHash', arguments ) },
  logout(){ return this.getter( 'logout', arguments ) },
}

export let auth0Manage = null;

const goHome = () => navigate(`/${i18n.language === 'en' ? '' : i18n.language}`);

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}


export const isAuthenticated = () => {
  if (!isBrowser) {
    return
  }

  return localStorage.getItem("isLoggedIn") === "true"
}

export const login = (username, password, setError) => {
  if (!isBrowser) {
    return
  }
  // auth.crossOriginVerification();
console.log( auth );
  auth.login({
    realm: databaseConnection,
    username,
    password,
  }, err => {
    const errorText = (err.description.rules && err.description.rules.length > 0 && err.description.rules[0].message) || err.description || '';
    setError(errorText);
  });
}

export const loginWithFacebook = (setError) => {
  if (!isBrowser) {
    return
  }
  auth.authorize({
    connection: 'facebook'
  }, err => {
    const errorText = (err.description.rules && err.description.rules.length > 0 && err.description.rules[0].message) || err.description || '';
    setError(errorText);
  });
}

export const loginWithWeibo = (setError) => {
  if(!isBrowser) return;
  auth.authorize({ connection: 'weibo' }, err => {
    console.log(err);
  });
}

export const signup = (email, password, firstname, lastname, country, city, receiveUpdates, receiveEmails, setError) => {
  if (!isBrowser) {
    return
  }
  auth.redirect.signupAndLogin({
      connection: databaseConnection,
      email: email,
      password: password,
      user_metadata: {
        firstname: firstname,
        lastname: lastname,
        country: country,
        city: city,
        // Auth0 doesn't to provide bool in signup user_metadata
        // if property is false, just set it empty
        receiveEmails: receiveEmails ? receiveEmails.toString() : '',
        receiveUpdates: receiveUpdates ? receiveUpdates.toString() : '',
      }
  }, err => {
    if(err) {
      console.log(err);
      const errorText = (err.description && err.description.rules && err.description.rules.length > 0 && err.description.rules[0].message) || err.description || '';
      setError(errorText);
    }
  });
}

export const resetPassword = (email, setEmailSentText) => {
  auth.changePassword({
    connection: databaseConnection,
    email,
  }, (err, res) => {
    if(res) {
      setEmailSentText(res);
    }
  });
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    cb()
    return
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    if (!isBrowser) {
      return
    }


    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()

    tokens.accessToken = authResult.accessToken
    tokens.idToken = authResult.idToken
    tokens.expiresAt = expiresAt

    user = authResult.idTokenPayload

    setUserData(cb)
    
    localStorage.setItem("user", JSON.stringify(user))
    localStorage.setItem("isLoggedIn", true)

  }
}

export const silentAuth = callback => {
  if (!isAuthenticated()) return callback()


  // scopes will not work because consent required.

  // auth.checkSession({
  //   audience: 'https://tkdev.auth0.com/api/v2/',
  //   scope: 'openid email profile read:current_user',
  // }, setSession(callback));

  auth.checkSession({
    audience: process.env.GATSBY_AUTH0_AUDIENCE,
    scope: 'openid email profile',
  }, setSession(callback));

}

export const setUserData = (cb = () => {}) => {
  auth.checkSession({
    audience: process.env.GATSBY_AUTH0_AUDIENCE,
    scope: 'read:current_user update:current_user_metadata',
  }, (err, authResult) => {
    
    if(err) {
      cb();
      return;
    };
    auth0Manage = isBrowser ? new window.auth0.Management({
      domain: process.env.GATSBY_AUTH0_DOMAIN,
      token: authResult.accessToken,
    }) : null;
    if(!auth0Manage) {
      cb();
      return;
    }
    auth0Manage.getUser(authResult.idTokenPayload.sub, (err, result) => {
      if(result) {
        localStorage.setItem("user", JSON.stringify(result));
        if(result && (!result.user_metadata || !result.user_metadata.firstname)) {
          const userMetadata = {
            city: '',
            country: '',
            firstname: result.given_name || '',
            lastname: result.family_name || '',
            receiveEmails: 'true',
            receiveUpdates: 'true',
          };
          updateUserData(userMetadata, cb);
        }
      }
      if(cb) cb();
    });
  });
}

export const updateUserData = (userMetadata, cb) => {
  const user = getProfile();
  auth0Manage.patchUserMetadata(user.user_id, userMetadata, (err, result) => {
    if(!err && result) {
      localStorage.setItem('user', JSON.stringify(result));
    }
    if(cb) cb();
  });
}

export const handleAuthentication = () => {
  if (!isBrowser) {
    return
  }
  const articleFallbackUrl = localStorage.getItem('articleFallbackUrl');
  auth.parseHash(setSession(articleFallbackUrl ? () => {navigate(articleFallbackUrl)} : goHome));
}

export const getProfile = () => {
  if (!isBrowser) {
    return
  }

  return JSON.parse(localStorage.getItem("user"))
}

export const logout = () => {
  if (!isBrowser) {
    return
  }

  localStorage.setItem("isLoggedIn", false)
  auth.logout()
}

class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }

    this.handleCheckSession = this.handleCheckSession.bind(this)
  }

  handleCheckSession = () => {
    this.setState({ loading: false })
  }

  UNSAFE_componentWillMount() {
    /*
    if(typeof window !== 'undefined') {
      globalHistory.listen(({ action }) => { 
        if(action === 'PUSH') {
          const { pathname } = window.location;
          const pageLngCode = pathname.split('/')[1] === '' ? 'en' : pathname.split('/')[1];
          const lngCode = supportedLngs.find(lng => lng === pageLngCode) || 'en';
          // i18next.changeLanguage(lngCode);
        }
      });
    }
    */

    // Set user pref language
    // if(
    //   typeof window !== 'undefined' &&
    //   globalHistory.location.pathname.indexOf(i18next.language) === -1 &&
    //   globalHistory.location.pathname.indexOf('callback') === -1 &&
    //   i18next.language !== 'en'
    // ) {
    //   console.log('SET USER PREF');
    //   const { pathname } = globalHistory.location;
    //   const pathnameCleared = pathname.replace('-zh-tc', '').replace('-zh', '');
    //   const lastLetterSlash = pathnameCleared[pathnameCleared.length - 1] === '/';
    //   const path = lastLetterSlash ? pathnameCleared.slice(0, pathnameCleared.length - 1) : pathnameCleared;
    //   window.location.pathname = contains('category', path) && i18next.language !== 'en' ?
    //   `${convertLinkLocale(path, i18next.language)}-${i18next.language.replace('_', '-')}` :
    //   convertLinkLocale(path, i18next.language);
    // }
  }

  componentDidMount() {
    silentAuth(this.handleCheckSession);
    if(typeof window !== "undefined" && window.innerWidth > MEDIUM_BREAKPOINT) {
      SmoothScroll({
        animationTime: 1600,
        stepSize: 200,

        accelerationDelta: 20,
        accelerationMax: 1,

        pulseAlgorithm   : true,
        pulseScale       : 3,
        pulseNormalize   : 1,
      });
    }
  }
  render() {
    return (
      !this.state.loading && (
        <>
          {this.props.children}
          {/* <CookiesPolicy /> */}
        </>
      )
    )
  }
}
export { SessionCheck }
