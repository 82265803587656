// ./gatsby-browser.js
import React from "react";
import { SessionCheck } from "./src/utils/auth"

export const wrapRootElement = ({ element }) => {
  return (
    <SessionCheck>
      <div id="main-wrapper">{element}</div>
    </SessionCheck>
  )
}

/* ensure gatsby renders the page form the page start */
export const shouldUpdateScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'auto'
  })
  return false 
}
